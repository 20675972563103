import PasswordStrength from "../lib/password_strength";

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "username", "password", "progressBar", "status", "wrapper", "submit" ]

  connect() {
    // this.usernameTarget.addEventListener('keyUp', e => this.test())
    // this.usernameTarget.addEventListener('keyDown', e => this.test())
    this.passwordTarget.addEventListener('keyup', e => this.test())
    this.passwordTarget.addEventListener('keydown', e => this.test())
    this.submitTarget.disabled = true;
  }

  test() {
    const username = this.hasUsernameTarget ? this.usernameTarget.value : ""
    const strength = PasswordStrength.test(username, this.passwordTarget.value)
    this.handler(strength)
  }

  handler(strength) {
    this.progressBarTarget.style.width = strength.score + '%'

    const d = this.data[strength.status]
    this.statusTarget.innerHTML = d[0]

    this.wrapperTarget.classList.remove("weak", "good", "strong")
    this.wrapperTarget.classList.add(d[1])

    this.submitTarget.disabled = !(strength.status == 'good' || strength.status == 'strong');

  }

  get data() {
    return {
      invalid: ['INVALIDA', 'invalid'],
      weak:    ['FRACA', 'weak'],
      good:    ['BOA',   'good'],
      strong:  ['FORTE', 'strong'],
    }
  }
}